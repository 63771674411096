import type { LocationQuery, LocationQueryRaw } from '#vue-router'
import type { FilterExpression, GetBrowseResultsResponseData } from '@constructor-io/constructorio-client-javascript'

interface ConstructorData {
  facets: GetBrowseResultsResponseData['facets']
  groups: GetBrowseResultsResponseData['groups']
  page: number
  results: GetBrowseResultsResponseData['results']
  resultsPerPage: number
  resultTotal: number
  sortOptions: GetBrowseResultsResponseData['sort_options']
  totalPages: number
}

interface ActiveFacet {
  facet: string
  label: string
  value: string
  range?: ['-inf' | number, number | 'inf']
}

interface ConstructorState extends ConstructorData {
  timestamp: number
  searchType: SearchType
  browseFacet: string
  browseFacetValue: string
  activeFacets: ActiveFacet[]
}

type SearchType = 'browse' | 'search'

interface ConstructorSearchParams {
  searchType: SearchType
  browseFacet: string
  browseFacetValue: string
  query: string
  facets: Record<string, string[]>
  page: number
  preFilterExpression?: FilterExpression
  resultsPerPage: number
  sortBy: string
  sortOrder: string
  groupDepth: number
}

/**
 * Creates a Constructor instance.
 *
 * @param {string} stateKey - The key for the state.
 * @param {ConstructorParams} params - The search parameters for the constructor.
 * @return {Object} An object containing the state and various functions for searching and manipulating the search params.
 */
function useConstructor(stateKey: string) {
  const { $constructor, $sitewideConfig } = useNuxtApp()

  const state = useState<ConstructorState>(`constructor-${stateKey}-state`, () =>
    shallowRef({
      searchType: 'browse',
      browseFacet: '',
      browseFacetValue: '',
      activeFacets: [],
      timestamp: 0,
      page: 1,
      totalPages: 1,
      resultsPerPage: 0,
      results: [],
      groups: [],
      resultTotal: 0,
      facets: [],
      sortOptions: [],
    })
  )

  const searchParams = useState<ConstructorSearchParams>(`constructor-${stateKey}-params`, () => ({
    searchType: 'browse',
    browseFacet: '',
    browseFacetValue: '',
    query: '',
    facets: {},
    groupDepth: 1,
    metadataToRetrieve: [],
    page: 1,
    preFilterExpression: undefined,
    resultsPerPage: 30,
    sortBy: 'relevance',
    sortOrder: 'descending',
  }))

  function setSearchType(type: SearchType) {
    searchParams.value.searchType = type
  }

  function setBrowseFacet(facet: string, value: string) {
    searchParams.value.browseFacet = facet
    searchParams.value.browseFacetValue = value

    setPage()
  }

  function setQuery(query: string) {
    searchParams.value.query = query

    setPage()
  }

  function setGroupDepth(depth: number) {
    searchParams.value.groupDepth = depth
  }

  function setPreFilterExpression(expression: FilterExpression) {
    searchParams.value.preFilterExpression = expression

    setPage()
  }

  function setResultsPerPage(resultsPerPage: number) {
    if (resultsPerPage < 1) return

    searchParams.value.resultsPerPage = resultsPerPage

    setPage()
  }

  /**
   * Returns the values of a given facet from the search params.
   *
   * @param {string} facet - The name of the facet to retrieve values for.
   * @return {Array} An array of values for the given facet, or an empty array if the facet does not exist.
   */
  function getFacetValues(facet: string) {
    return searchParams.value.facets[facet] ?? []
  }

  function addFacetValue(facet: string, value: string) {
    // Create an empty array if it doesn't exist
    searchParams.value.facets[facet] ??= []

    // Only add the value if it isn't already in the array
    if (searchParams.value.facets[facet].includes(value)) return

    // Add the value
    searchParams.value.facets[facet].push(value)

    // Reset the page to 1
    setPage()
  }

  function removeFacetValue(facet: string, value: string) {
    // Only remove a facet value if the facet already exists
    if (!searchParams.value.facets[facet]) return

    // Remove the value
    searchParams.value.facets[facet] = searchParams.value.facets[facet].filter((val) => val !== value)

    // If the array is empty then delete the facet
    if (searchParams.value.facets[facet].length === 0) delete searchParams.value.facets[facet]

    // Reset the page to 1
    setPage()
  }

  function clearFacets(facet?: string) {
    // Only clear a facet if a facet is passed, otherwise clear all facets
    if (facet) delete searchParams.value.facets[facet]
    else searchParams.value.facets = {}

    // Reset the page to 1
    setPage()
  }

  function setSortBy(sortBy?: string, sortOrder?: string) {
    searchParams.value.sortBy = sortBy ?? 'relevance'
    searchParams.value.sortOrder = sortOrder ?? 'descending'

    // Reset the page to 1
    setPage()
  }

  function setPage(page?: number) {
    const pageNumber = page && page > 0 ? page : 1

    searchParams.value.page = pageNumber
  }

  function getSearchParamsForQuery() {
    const { searchType, facets, page, sortBy, sortOrder, query } = searchParams.value

    const q: LocationQueryRaw = {}

    const { sameDayShipping, ...rest } = facets

    // Format facet state into the query and add them if we have any values
    const filter = Object.entries(rest).map(([facet, values]) => `${facet}:${values.join('|')}`)
    if (filter.length > 0) q.filter = filter

    if (sameDayShipping?.includes('True')) q.sameDayShipping = 'true'

    // Only add the value to the query if it's not the default value
    if (searchType === 'search' && query) q.q = query
    if (page > 1) q.page = page
    if (sortBy !== 'relevance') {
      q.sortBy = sortBy
      q.sortOrder = sortOrder
    }

    return q
  }

  function setSearchParamsFromQuery(query: LocationQuery) {
    const { page, sortBy, sortOrder, filter, sameDayShipping, q } = query

    clearFacets()

    if (filter) {
      const filters = Array.isArray(filter) ? filter : [filter]

      filters.forEach((filter) => {
        const [facet, value] = filter!.split(':')

        const values = value.split('|') ?? []

        values.forEach((value) => {
          addFacetValue(facet, value)
        })
      })
    }

    if (sameDayShipping) addFacetValue('sameDayShipping', 'True')

    if (q) setQuery(q.toString())

    if (sortBy && sortOrder) setSortBy(sortBy.toString(), sortOrder.toString())

    if (page) {
      // Convert page to a number
      const pageValue = +page.toString()
      // if the value is NaN then we shouldn't set the page
      if (!isNaN(pageValue)) setPage(pageValue)
    }
  }

  async function search() {
    // If we are on the server we should never update the timestamp because we don't want any weird behavior
    // when we are on the client. The main reason this value is even stateful is because if we have multiple instances
    // of the same index we want them to be updated together. While this is very unlikely its good to cover our bases.
    const requestTimestamp = import.meta.client ? Date.now() : 0

    // Destructure values out of the the search params so we have a snapshot of what the request was before it was made.
    const { searchType, browseFacet, browseFacetValue, query } = searchParams.value

    let data: ConstructorData | undefined

    // If we are in browse mode, we must have a browse facet and value to make a search
    if (searchType === 'browse' && browseFacet && browseFacetValue) {
      data = await getBrowseResults(browseFacet, browseFacetValue)
      // If we are in search mode, we must have a query to make a search
    } else if (searchType === 'search' && query) {
      // do search stuff
      data = await getSearchResults(query)
    }

    // We only update the state if we have data and the request is newer than the last successful request
    if (data && requestTimestamp >= state.value.timestamp) {
      const facets = data.facets
        .filter((facet) => {
          switch (facet.name) {
            case 'specials':
              return !$sitewideConfig.config.nonTransactionalEnabled

            case 'sameDayShipping':
              return $sitewideConfig.config.sameDayShippingEnabled

            default:
              // TODO: Add support for range facets
              // Right now we don't support the range facet so we need to remove it from the facets
              return facet.type !== 'range'
          }
        })
        .map((facet) => {
          if (facet.name === 'salePrice') {
            facet.options.forEach((option) => {
              const [min, max] = option.range

              if (max === 'inf') {
                option.display_name = `${formatCents(min, 0)} and above`
              } else if (min === '-inf') {
                option.display_name = `${formatCents(max, 0)} and below`
              } else {
                option.display_name = `${formatCents(min, 0)} - ${formatCents(max, 0)}`
              }
            })
          }

          return facet
        })

      const activeFacets = facets.flatMap((facet) =>
        facet.options
          .filter((option) => option.status === 'selected')
          .map((option) => {
            return {
              facet: facet.name,
              label: option.display_name,
              value: option.value,
              range: option.range,
            }
          })
      )

      state.value = {
        timestamp: requestTimestamp,
        searchType,
        browseFacet,
        browseFacetValue,
        activeFacets,
        ...data,
        facets,
      }
    }

    return data
  }

  // Currently browse and search take the same search params so we can use the same function to get the search params
  function getSearchParams() {
    const { facets, groupDepth, page, preFilterExpression, resultsPerPage, sortBy, sortOrder } = searchParams.value

    return {
      filters: facets,
      page: page,
      resultsPerPage: resultsPerPage,
      sortBy: sortBy,
      sortOrder: sortOrder,
      fmtOptions: {
        groups_max_depth: groupDepth,
      },
      preFilterExpression,
    }
  }

  async function getBrowseResults(browseFacet: string, browseFacetValue: string): Promise<ConstructorData> {
    const searchParams = getSearchParams()
    const userParams = $constructor.userParams

    // Note: On the server, the 4th arg is user params. On the client its not needed so $constructor.userParams is undefined.
    // In TS, I forced the client type to always browser version of the constructor client. Thats why it looks like we are passing userParams into the NetworkParameters arg.
    const data = await $constructor.client.browse.getBrowseResults(
      browseFacet,
      browseFacetValue,
      searchParams,
      userParams
    )

    return {
      page: searchParams.page,
      resultsPerPage: searchParams.resultsPerPage,
      totalPages: Math.ceil((data.response?.total_num_results ?? 0) / searchParams.resultsPerPage),
      facets: data.response?.facets ?? [],
      groups: data.response?.groups ?? [],
      results: data.response?.results ?? [],
      resultTotal: data.response?.total_num_results ?? 0,
      sortOptions: data.response?.sort_options ?? [],
    }
  }

  async function getSearchResults(query: string): Promise<ConstructorData | undefined> {
    const searchParams = getSearchParams()
    const userParams = $constructor.userParams

    // Note: On the server, the 3th arg is user params. On the client its not needed so $constructor.userParams is undefined.
    // The reason for this is exactly the same as the getBrowseResults function call.
    const data = await $constructor.client.search.getSearchResults(query, searchParams, userParams)

    // If we have a redirect then we need to navigate to it
    if (data.response.redirect) {
      await navigateTo(data.response.redirect.data.url, { redirectCode: 302, replace: true })
      return
    }

    return {
      page: searchParams.page,
      resultsPerPage: searchParams.resultsPerPage,
      totalPages: Math.ceil((data.response?.total_num_results ?? 0) / searchParams.resultsPerPage),
      facets: data.response?.facets ?? [],
      groups: data.response?.groups ?? [],
      results: data.response?.results ?? [],
      resultTotal: data.response?.total_num_results ?? 0,
      sortOptions: data.response?.sort_options ?? [],
    }
  }

  return {
    state,
    setSearchType,
    setBrowseFacet,
    setQuery,
    setGroupDepth,
    setPreFilterExpression,
    setResultsPerPage,
    getFacetValues,
    addFacetValue,
    removeFacetValue,
    clearFacets,
    setSortBy,
    setPage,
    getSearchParamsForQuery,
    setSearchParamsFromQuery,
    search,
  }
}

export default useConstructor
export type ConstructorInstance = ReturnType<typeof useConstructor>
